/*
styles.scss
*/

/* B */
#badge-personal {
	height: 25px;
	width: 200px;
	font-size: 16px;
}
#button-style-1 {
	background-color: $green-1;
	color: white;
	font-weight: 700;
	align-content: center;
	justify-content: center;
}

#button-style-2 {
	background-color: $white-1;
	color: black;
	font-weight: 700;
}

#button-style-3 {
	margin-right: 5px;
}

#button-grey-style-pos {
	background-color: #1b2c3f;
	color: white;
	font-weight: 700;
	height: 100%;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    
}

#button-green-style-pos {
	background-color: $success;
	color: white;
	font-weight: bold;
	font-size: 24px;
	height: 65px;
	width: 100%;
	margin-top: 10px;
	padding: 0;
	transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

#button-cell-rounded {
	border: none;
	text-align: center;
	text-decoration: none;
	font-size: 12px;
	border-radius: 100%;
	height: 25px;
	width: 25px;
}
/* C */
#card-into-off-canvas {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

#card-principal-pos {
	height: 100%;
}

#card-secundary-info {
	height: 100%;
    width: 100%;
}

#card-secundary-total {
	height: 100%;
    width: 100%;
}

#card-style-2 {
	box-shadow: 6px 6px 8px 0px rgba(0, 0, 0, 0.1);
	margin-left: 30px;
}

#card-footer-off-canvas {
	background-color: white;
	margin-bottom: 20px;
	margin-top: 10px;
}

#card-personal-data {
	height: 35vh;
}

/* D */
#div-button-cell-r {
	display: grid;
	align-content: center;
	justify-content: center;
	margin-right: 3px;
}

#div-grey-buttons-container{
    width: 40%;
}
#div-button-grey {
	margin-right: 5px;
    width: 40%;
}
#div-button-cell-l {
	display: grid;
	align-content: center;
	justify-content: center;
	margin-left: 3px;
}
#div-prinipal-card-pos{
    margin-bottom: 10px;
    height: 73vh;
}
#div-secundary-info {
	margin-right: 10px;
    width: 30%;
}
#div-secondary-card-container{
    height: 15vh;
}

#div-secundary-total {
	margin-right: 10px;
    width: 30%;
}
#div-personal-badge {
	display: flex;
	align-content: center;
	justify-content: center;
	vertical-align: middle;
}
#div-personal-data {
	height: 100vh;
}
#div-page-content {
	height: 100vh;
}
#div-container {
	height: 40vh;
}

#div-principal{
    height: 65vh;
    margin-bottom: 10px;
}

#div-login-background {
    background-image: url('../../../images/login-bg.jpg');
    height: 100vh;
    width: 100%;
}

#div-style-1 {
    margin-left: 100px;
    margin-right: 100px;
    justify-content: center;
    align-items: center;
    display: block;
    height: 100vh;
}

#div-style-2 {
	margin-top: 60px;
}

#div-style-3 {
	margin-top: 4px;
	margin-bottom: 8px;
}

#div-style-4 {
	height: 65px;
	width: 100%;
	margin-top: 22px;
	margin-bottom: 25px;
}

#div-style-5 {
	background-color: $white-1;
	border-radius: 10px;
}

#div-style-6 {
	background-color: $white-2;
	border-radius: 8px 8px 8px 8px;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 4px;
	padding-bottom: 4px;
}

#div-style-7 {
	display: flex;
	justify-content: center;
	align-items: center;
}

#div-style-8 {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $green-2;
	border-radius: 8px 8px 8px 8px;
	margin-top: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 700;
}

#div-style-9 {
	display: flex;
	border-radius: 8px 8px 8px 8px;
	padding: 10px;
	margin-top: 4px;
	margin-bottom: 4px;
	border: 1px solid $green-2;
}

#div-style-10 {
	background-color: $green-2;
	width: 2px;
	padding-top: 4px;
	padding-bottom: 4px;
	margin-left: 8px;
	margin-right: 8px;
}

#div-style-11 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

#div-style-12 {
	margin-left: 6px;
}

#div-style-13 {
	border-radius: 6px 6px 6px 6px;
	border: 1px solid $gray-1;
}

#div-card-personal{
	height: 330px;
	margin-bottom: 5px;
}

#div-card-method{
	height: 262px;
	margin-bottom: 5px;
}

#div-card-total{
	height: 140px;
	margin-bottom: 5px;
}

#div-style-14 {
	width: 80%;
	position: absolute;
	top: 40px;
	right: 26px;
	background: $gray-2;
	z-index: 9;
}

#div-style-15 {
	background-color: $white-2;
	border-radius: 8px 8px 8px 8px;
	padding-left: 8px;
}
#div-icon-gray-button {
	justify-content: center;
}
#div-style-16 {
	width: 80%;
	position: absolute;
	top: 272px;
	right: 20px;
	background: $gray-2;
	z-index: 9;
}

#dropdwon-export {
   background-color: transparent;
   color: #ff0;
}

/* F */
.font-weight-lighter {
    font-weight: lighter

}


/* I */

#icon-style-1 {
	cursor: pointer;
	color: $green-1;
}

#icon-style-2 {
	cursor: pointer;
	color: $danger;
	display: flex;
	justify-content: center;
	align-items: center;
}
#icon-green-button {
	font-size: 22px;
}

#icon-gray-button {
	font-size: 32px;
}

#icon-style-3 {
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

#img-style-1 {
	height: 78px;
}

#img-bgc-g {
	background-color: $blue-1;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

input[type='file']::file-selector-button {
	border-radius: 4px;
	padding: 0 16px;
	height: 40px;
	cursor: pointer;
	background-color: $blue-2;
	border: 1px solid rgba(0, 0, 0, 0.16);
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
	margin-right: 16px;
	transition: background-color 200ms;
	color: white;
}

/* file upload button hover state */
input[type='file']::file-selector-button:hover {
	background-color: $blue-3;
}

/* file upload button active state */
input[type='file']::file-selector-button:active {
	background-color: $white-4;
}
#input-payment-style{
	height: 30px;
}

#input-style-1 {
	background-color: $white-2;
	border: none;
	height: 20px;
	padding: 0px;
}

#input-style-2::-ms-expand {
	display: none;
}

#input-style-3 {
	background-color: $white-2;
	border: none;
}

/* L */

#login-message {
    color: black;
}

#login-form {
    color: black;
}

/* M */

.modal-buttons {
	width: 100px;
}

/* O */
#off-canvas {
	width: 35%;
	margin-top: 5%;
	border-radius: 8px 0px 0px 8px;
}

#off-canvas-header {
	background-color: $white-3;
	border-radius: 8px 0px 0px 0px;
}

#off-canvas-personnel {
    margin-top: 5%;
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-school {
    top: 5%;
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-students {
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-tutor {
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-teacher {
    top: 10%;
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-market {
    top: 30%;
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-health {
    top: 5%;
    border-radius: 8px 0px 0px 8px;
}

#off-canvas-education-offer {
    top: 20%;
    border-radius: 8px 0px 0px 8px;
}

@media (min-width: 0px) {
    #off-canvas-personnel {
        width: 100%;
    }

    #off-canvas-school {
      width: 100%;
    }

    #off-canvas-students {
        width: 100%;
    }

    #off-canvas-tutor {
        width: 100%;
    }

    #off-canvas-teacher {
        width: 100%;
    }

    #off-canvas-market {
        width: 100%;
    }

    #off-canvas-health {
        width: 100%;
    }

    #off-canvas-education-offer {
        width: 100%;
    }

    #card-style-2 {
        margin: auto;
        margin-top: 32px;
    }

    #login-message {
        margin-top: 80px;
    }

    #login-form {
        padding-bottom: 80px;
    }
}

@media (min-width: 576px) {
    #off-canvas-personnel {
        width: 90%;
    }

    #off-canvas-school {
      width: 90%;
    }

    #off-canvas-tutor {
        width: 90%;
    }

    #off-canvas-teacher {
        width: 95%;
    }

    #off-canvas-market {
        width: 95%;
    }

    #off-canvas-education-offer {
        width: 90%;
    }

    #card-style-2 {
        margin: auto;
        margin-top: 32px;
    }

    #login-message {
        margin-top: 80px;
    }

    #login-form {
        padding-bottom: 80px;
    }
}

@media (min-width: 768px) {
    #off-canvas-personnel {
        width: 80%;
    }

    #off-canvas-school {
      width: 80%;
    }

    #off-canvas-students {
        width: 80%;
    }

    #off-canvas-tutor {
        width: 80%;
    }

    #off-canvas-teacher {
        width: 80%;
    }

    #off-canvas-market {
        width: 80%;
    }

    #off-canvas-health {
        width: 80%;
    }

    #off-canvas-education-offer {
        width: 80%;
    }

    #card-style-2 {
        margin: auto;
        margin-top: 32px;
    }

    #login-message {
        margin-top: 80px;
    }

    #login-form {
        padding-bottom: 80px;
    }
}

@media (min-width: 992px) {
    #off-canvas-personnel {
        width: 60%;
    }

    #off-canvas-school {
      width: 70%;
    }

    #off-canvas-students {
        width: 60%;
    }

    #off-canvas-tutor {
        width: 60%;
    }

    #off-canvas-teacher {
        width: 60%;
    }

    #off-canvas-market {
        width: 60%;
    }

    #off-canvas-health {
        width: 50%;
    }

    #off-canvas-education-offer {
        width: 70%;
    }

    #card-style-2 {
        margin: auto;
        margin-top: 32px;
    }

    #login-message {
        margin-top: 80px;
    }

    #login-form {
        padding-bottom: 80px;
    }
}

@media (min-width: 1200px) {
    #off-canvas-personnel {
        width: 50%;
    }

    #off-canvas-school {
      width: 60%;
    }

    #off-canvas-students {
        width: 45%;
    }

    #off-canvas-tutor {
        width: 50%;
    }

    #off-canvas-teacher {
        width: 50%;
    }

    #off-canvas-market {
        width: 50%;
    }

    #off-canvas-health {
        width: 45%;
    }

    #off-canvas-education-offer {
        width: 60%;
    }

    #div-style-1 {
        margin-left: 100px;
        margin-right: 100px;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100vh;
    }
}

@media (min-width: 1400px) {
    #off-canvas-personnel {
        width: 35%;
    }
    
    #off-canvas-school {
      width: 50%;
    }

    #off-canvas-students {
        width: 35%;
    }

    #off-canvas-tutor {
        width: 35%;
    }

    #off-canvas-teacher {
        width: 40%;
    }

    #off-canvas-market {
        width: 40%;
    }

    #off-canvas-health {
        width: 35%;
    }

    #off-canvas-education-offer {
        width: 50%;
    }
}

/* P */

#p-style-1 {
	font-size: 28px;
	line-height: 120%;
}

#p-style-2 {
	font-size: 63px;
	font-weight: 700;
	line-height: 100%;
    color: black;
}

#p-style-3 {
	font-size: 35px;
	font-weight: 700;
	text-align: center;
    color: black;
}

#p-style-4 {
	font-size: 22px;
	text-align: center;
}

#p-style-5 {
	color: $green-1;
	font-size: 18;
}

/* R */

#row-style-1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* S */

#span-badge {
	width: 120px;
	height: 30px;
	font-weight: 700;
}

.span-tittle-text {
	margin-left: 10px;
	font-weight: 700;
	font-size: 25px;
}

.span-tittle-text-table {
	margin-left: 10px;
	font-weight: 700;
	font-size: 20px;
}
#span-payment-subtitle{
	font-size: 18px;
}
#span-badge {
	width: 120px;
	height: 30px;
	font-size: 14px;
	font-style: normal;
	display: table-cell;
	vertical-align: middle;
}
#span-total-card{
	font-size: 35px;
	font-weight: bold;
}
#span-cash-card{
	font-size: 35px;
	color: $success;
	font-weight: bold;
}
#span-payment-label{
	font-size: 20px;
}

#span-balance-label{
	font-size: 18px;
	color: $success;
}

#spinner-style-1 {
	color: $green-1;
}

/* T */
#text-style-1 {
	color: $black-1;
}

.td-teacher-column-schedule-icon-delete{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px;
    cursor: pointer;
    color: red;
    z-index: 10001;

}
.td-teacher-column-schedule{
    border: 1px solid #dee2e6;
    position: relative;
    width: 14.28%;
}

#tr-style-1 {
	cursor: pointer;
}

#tr-style-2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white-2;
}
